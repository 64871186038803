<template>
  <PageContainer>
    <template v-slot:header>
      <v-col class="d-flex">
        <v-btn icon size="40px" @click="goBack">
          <v-icon color="primaryblack2" size="24px">mdi-chevron-left</v-icon>
        </v-btn>
        <span class="heading-2"> {{ form.id ? "Update" : "Create" }} schedule </span>
      </v-col>
    </template>
    <v-progress-linear v-if="isLoading" indeterminate />
    <v-card class="organization-form">
      <v-col>
        <Select
          :hide-details="true"
          :items="healthcareServices"
          :label-active="true"
          :labelActive="true"
          :multiple="true"
          :value="form.actorHealthcareServiceIds"
          fieldName="actorHealthcareServiceIds"
          item-text="name"
          item-value="id"
          label="For healthcare services"
          @change="changeFormField"
        />
      </v-col>
      <v-col>
        <span class="heading-4 text-left">Slots availability</span>
      </v-col>
      <v-divider />
      <v-col class="px-0">
        <div v-for="(slot, index) in slots" :key="index" class="mb-2">
          <v-col class="d-flex align-center gap-1">
            <DatePicker
              :dense="true"
              :height="34"
              :hideDetails="true"
              :isYearFirst="false"
              :labelActive="true"
              :value="slot.date"
              placeholder="Date"
              @setDate="(date) => (slot.date = date)"
            />
            <v-btn class="pointer" icon rounded @click="removeFormSlot(index)">
              <v-icon color="error" size="24px">mdi-minus-circle</v-icon>
            </v-btn>
            <v-btn class="pointer" icon rounded @click="removeFormSlot(index)">
              <v-icon color="primary" size="24px">mdi-content-duplicate</v-icon>
            </v-btn>
          </v-col>
          <div class="pol-container">
            <div :style="dynamicStyles" class="pol-border">
              <v-icon class="mr-2" color="primary" size="24px">mdi-clock</v-icon>
              <TimePicker
                :hide-icon="true"
                :labelActive="true"
                :value="slot.start"
                class="time-picker"
                placeholder="Start Time"
                @change="(time) => (slot.start = time)"
              />
            </div>
            <TimePicker
              :hide-icon="true"
              :labelActive="true"
              :value="slot.end"
              class="time-picker"
              placeholder="End Time"
              @change="(time) => (slot.end = time)"
            />
          </div>
          <div class="px-4">
            <v-switch :value="slot.repeat" color="primary" label="Repeat" @change="(value) => (slot.repeat = value)" />
            <div v-if="slot.repeat" class="d-flex justify-between gap-1 align-center">
              Repeat every:
              <BaseInput
                v-model="slot.repeatInterval"
                class="w-100"
                type="number"
                @change="(value) => (slot.repeatInterval = value)"
              />
              <Select
                :hide-details="true"
                :items="repeatTypeValues"
                :label-active="true"
                :labelActive="true"
                :value="slot.repeatType"
                class="w-130"
                fieldName="repeatType"
                item-text="name"
                item-value="value"
                label="Repeat type"
                @change="(value) => (slot.repeatType = value)"
              />
            </div>
            <div v-if="slot.repeatType === SlotRepeatTypeEnum.weekly" class="d-flex justify-between gap-1 align-center">
              is weekly
            </div>
          </div>
          <v-divider class="mt-3" />
        </div>
      </v-col>
      <v-col class="d-flex justify-center">
        <IconicButton rounded size="small" text="Add time slot" @click="appendFormSlot" />
      </v-col>

      <v-divider />
      <v-card-actions class="d-flex justify-end">
        <PrimaryButton :loading="isSaving" text="Save" @click="performSave" />
      </v-card-actions>
    </v-card>
  </PageContainer>
</template>
<script>
import { upperFirst } from "lodash-es";
import { mapActions, mapState, mapWritableState } from "pinia";

import { ScheduleApi } from "@/api/schedule";
import DatePicker from "@/components/shared/DatePicker.vue";
import TimePicker from "@/components/shared/TimePicker.vue";
import BaseInput from "@/components/uikit/BaseInput.vue";
import IconicButton from "@/components/uikit/IconicButton.vue";
import PageContainer from "@/components/uikit/PageContainer.vue";
import PrimaryButton from "@/components/uikit/PrimaryButton.vue";
import Select from "@/components/uikit/Select.vue";
import { useHealthcareServiceStore } from "@/pinia-store/healthcareServices";
import { SlotRepeatTypeEnum, useScheduleStore } from "@/pinia-store/schedule";
import { routesEnum } from "@/types/Routes.enum";

export default {
  name: "ScheduleEdit",
  data() {
    return {
      isLoading: false,
      backgroundArrowImageUrl: require("@/assets/right-arrow-border.svg"),
      repeatTypeValues: Object.values(SlotRepeatTypeEnum).map((v) => ({ value: v, name: upperFirst(v) })),
    };
  },
  components: { BaseInput, TimePicker, IconicButton, DatePicker, PrimaryButton, Select, PageContainer },
  computed: {
    SlotRepeatTypeEnum() {
      return SlotRepeatTypeEnum;
    },
    dynamicStyles() {
      return {
        backgroundImage: `url(${this.backgroundArrowImageUrl})`,
      };
    },
    ...mapState(useHealthcareServiceStore, ["healthcareServices"]),
    ...mapState(useScheduleStore, ["isSaving"]),
    ...mapWritableState(useScheduleStore, ["form"]),
    slots() {
      return this.form.slots || [];
    },
  },
  methods: {
    ...mapActions(useHealthcareServiceStore, ["getHealthcareServices"]),
    ...mapActions(useScheduleStore, [
      "changeFormField",
      "changeFormSlotField",
      "saveForm",
      "setForm",
      "appendFormSlot",
      "removeFormSlot",
      "changeSlotRepeat",
    ]),
    async performSave() {
      await this.saveForm();
    },
    goBack() {
      this.$router.push({ name: routesEnum.organizationSchedule });
    },

    changeStartDate(date) {
      this.setForm({ ...this.form, planningHorizon: { ...(this.form.planningHorizon || {}), start: date } });
    },
    changeEndDate(date) {
      console.log(date);
      this.setForm({ ...this.form, planningHorizon: { ...(this.form.planningHorizon || {}), end: date } });
    },
    onChangeTime(time) {
      console.log(time);
    },
  },

  async mounted() {
    if (this.$route.params.id) {
      this.isLoading = true;
      const item = await ScheduleApi.find(this.$route.params.id);
      this.setForm(item);
      this.isLoading = false;
    }

    await this.getHealthcareServices(this.$route.params.organizationId);
    console.log("Schedule mounted");
  },
};
</script>

<style lang="scss" scoped>
.organization-form {
  max-width: 450px;
  margin: 0 auto;
  margin-top: 30px;
  width: 90%;
}

:deep {
  .time-picker {
    min-width: 120px;

    .base-input .v-input__control .v-input__slot fieldset {
      border: none !important;
    }

    .v-input__slot {
      border: none !important;
      cursor: pointer !important;
    }
  }

  .w-130 {
    width: 130px;

    .v-input__control {
      width: inherit;
    }
  }

  .w-100 {
    width: 100px;

    .v-input__control {
      width: inherit;
    }
  }
}

.pol-container {
  border-bottom: 1px solid #eeeff7;
  border-top: 1px solid #eeeff7;
  display: flex;
  gap: 5px;
  align-items: center;
  padding: 0 12px;
  margin-bottom: 12px;
}

.pol-border {
  // clip-path: polygon(80% 0, 0 0, 0 100%, 80% 100%, 100% 50%);
  display: flex;
  background-position: right center;
  border: none;
  padding: 10px 80px 10px 10px;
  background-size: auto 100%;
}
</style>
